import { Component } from '@angular/core';
import {CustomButtonComponent} from "../custom-button/custom-button.component";
import {NgIf, NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-user-error-page',
  standalone: true,
  imports: [
    CustomButtonComponent,
    NgIf,
    NgOptimizedImage
  ],
  templateUrl: './user-error-page.component.html',
  styleUrl: './user-error-page.component.scss'
})
export class UserErrorPageComponent {

}
