export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
export const REMOVE_RACE_WITHOUT_CONFIRMATION = 'remove-race-without-confirmation';
export const USER_UNIT_SYSTEM = 'user_unit_system';
export const USER_DATE_FORMAT = 'user_date_format';
export const RACE_ELEVATION_DATA = 'race_elevation_data';

export const AI_REQUESTS = [
  'Ask me about course or event info',
  'Assess my readiness for the race distance',
  'Recommend gear for event conditions',
  'Replicate uphill segments of the race near me'
]

export const RACE_ID = 'race_id';
export const SHOW_MAP_OPTION = 'Show Map';
export const CHOOSE_SEGMENT_OPTION = 'Choose This Segment';
export const SET_RADIUS_OPTION = 'Set Radius';
export const SCREEN_SMALL_SIZE = 1365;
export const RACE_LOGO_URL = 'race_logo_url';
export const RACE_SITE_URL = 'race_site_url';
export const ACCESS_CODE = 'access_code';
export const AI_REQUEST_TIMEOUT = 3 * 60 * 1000

export const GEAR_PRODUCTS_IMAGES = [
  { name: 'Armsleeves', image: 'assets/images/product-armsleeves.png' },
  { name: 'Gloves', image: 'assets/images/product-gloves.png' },
  { name: 'Headwear', image: 'assets/images/product-headwear.png' },
  { name: 'Long sleeve shirts', image: 'assets/images/product-long-sleeve.png' },
  { name: 'Short sleeve shirts', image: 'assets/images/product-short-sleeve.png' },
  { name: 'Road running shoes', image: 'assets/images/product-road-running-shoes.png' },
  { name: 'Shorts', image: 'assets/images/product-shorts.png' },
  { name: 'Tank top', image: 'assets/images/product-tank-top.png' },
  { name: 'Tights', image: 'assets/images/product-tights.png' },
  { name: 'Trail running shoes', image: 'assets/images/product-trail-running-shoes.png' },
]
