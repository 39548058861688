<div>
  <div class="wrapper">
    <div class="container">
      <div class="search-input-wrapper">
      <span class="search-icon">
        <img
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzU4M18xOTAzIiBzdHlsZT0ibWFzay10eXBlOmFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IiNEOUQ5RDkiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swXzU4M18xOTAzKSI+CjxwYXRoIGQ9Ik0xOS41NDIzIDIwLjU3N0wxMy4yNjE2IDE0LjI5NkMxMi43NjE2IDE0LjcwODggMTIuMTg2NiAxNS4wMzE5IDExLjUzNjYgMTUuMjY1M0MxMC44ODY2IDE1LjQ5ODYgMTAuMjE0MSAxNS42MTUzIDkuNTE5MyAxNS42MTUzQzcuODEwMTQgMTUuNjE1MyA2LjM2MzY0IDE1LjAyMzUgNS4xNzk4IDEzLjg0QzMuOTk1OTcgMTIuNjU2NSAzLjQwNDA1IDExLjIxMDMgMy40MDQwNSA5LjUwMTUxQzMuNDA0MDUgNy43OTI4NSAzLjk5NTggNi4zNDYxOCA1LjE3OTMgNS4xNjE1MkM2LjM2MjggMy45NzcwMiA3LjgwODk3IDMuMzg0NzcgOS41MTc4IDMuMzg0NzdDMTEuMjI2NSAzLjM4NDc3IDEyLjY3MzEgMy45NzY2OCAxMy44NTc4IDUuMTYwNTFDMTUuMDQyMyA2LjM0NDM1IDE1LjYzNDYgNy43OTA4NSAxNS42MzQ2IDkuNTAwMDFDMTUuNjM0NiAxMC4yMTQyIDE1LjUxNDcgMTAuODk2MyAxNS4yNzUxIDExLjU0NjNDMTUuMDM1MiAxMi4xOTYzIDE0LjcxNTMgMTIuNzYxNiAxNC4zMTUzIDEzLjI0MjNMMjAuNTk2MSAxOS41MjNMMTkuNTQyMyAyMC41NzdaTTkuNTE5MyAxNC4xMTU1QzEwLjgwNzggMTQuMTE1NSAxMS44OTkxIDEzLjY2ODMgMTIuNzkzMyAxMi43NzRDMTMuNjg3NiAxMS44Nzk4IDE0LjEzNDggMTAuNzg4NSAxNC4xMzQ4IDkuNTAwMDFDMTQuMTM0OCA4LjIxMTUxIDEzLjY4NzYgNy4xMjAxOCAxMi43OTMzIDYuMjI2MDFDMTEuODk5MSA1LjMzMTY4IDEwLjgwNzggNC44ODQ1MiA5LjUxOTMgNC44ODQ1MkM4LjIzMDggNC44ODQ1MiA3LjEzOTQ3IDUuMzMxNjggNi4yNDUzIDYuMjI2MDFDNS4zNTA5NyA3LjEyMDE4IDQuOTAzOCA4LjIxMTUxIDQuOTAzOCA5LjUwMDAxQzQuOTAzOCAxMC43ODg1IDUuMzUwOTcgMTEuODc5OCA2LjI0NTMgMTIuNzc0QzcuMTM5NDcgMTMuNjY4MyA4LjIzMDggMTQuMTE1NSA5LjUxOTMgMTQuMTE1NVoiIGZpbGw9IiM2MjcxRkYiLz4KPC9nPgo8L3N2Zz4K"
          alt="Search Icon"
        />
      </span>
        <span class="divider"></span>
        <input
          [(ngModel)]="userMessage"
          placeholder="Ask anything about our events or course maps..."
          class="search-input"
          (keydown.enter)="sendMessage()"
        >
      </div>
      <app-prompts-list [prompts]="prompts" (handlePromptClick)="openDrawer($event)"></app-prompts-list>
    </div>
  </div>
  <ng-container *ngIf="showLoginIframe">
    <app-auth-drawer [isOpen]="isDrawerOpen" [keycloakLoginUrl]="keycloakLoginUrl" (handleChangeDrawerState)="handleChangeDrawerState($event)"></app-auth-drawer>
  </ng-container>
  <ng-container *ngIf="isAIDrawerOpen">
    <app-ai-chat
      [isOpen]="isAIDrawerOpen"
      [prompt]="selectedPrompt"
      [elevations_data]="elevationsData"
      [units]="userUnitSystem"
      (handleChangeDrawerState)="handleChangeDrawerState($event)"
      [token]="token"
    ></app-ai-chat>
  </ng-container>
</div>
