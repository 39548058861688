<div>
  <app-header title="Manual Entry" (handleBackClick)="onBackClick()"></app-header>
  <div class="form-wrapper">
    <form [formGroup]="raceForm" (ngSubmit)="onSubmit()">
      <div>
        <span class="input-label">Race Name</span>
        <mat-form-field>
          <input matInput id="name" formControlName="name" placeholder="Ex. Run for Life Marathon">
        </mat-form-field>
      </div>
      <div>
        <span class="input-label">Date</span>
        <div style="width: 100%; padding: 16px 0">
          <input
            [owlDateTime]="dt1"
            [owlDateTimeTrigger]="dt1"
            (dateTimeChange)="onCalendarChange($event)"
            formControlName="date"
            placeholder="Ex. 05/01/2024 14:30"
            class="text-field"
            [min]="minDate"
            id="datePicker"
          >
          <owl-date-time [hour12Timer]="parsedUserUnitSystem === 'imperial'" [firstDayOfWeek]="1" #dt1></owl-date-time>
        </div>
        <div *ngIf="raceForm.get('date')?.hasError('invalidDate') && raceForm.get('date')?.touched">
          <mat-error>Invalid date format</mat-error>
        </div>
        <div *ngIf="raceForm.get('date')?.hasError('dateNotBeforeToday') && raceForm.get('date')?.touched" style="margin-top: -10px; margin-bottom: 10px">
          <mat-error>Date cannot be before today</mat-error>
        </div>
      </div>
      <div>
        <span class="input-label">Data</span>
        <div class="data-switcher">
          <mat-button-toggle-group appearance="legacy" [hideSingleSelectionIndicator]="true" [(value)]="selectedOption" (change)="onToggleChange($event.value)" class="toggle-group">
            <mat-button-toggle  value="upload">Upload from GPX</mat-button-toggle>
            <mat-button-toggle value="manual">Enter manually</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div *ngIf="selectedOption === 'upload'; else manualAddTemplate">
        <div class="upload-container">
          <input type="file" id="fileInput" (change)="onFileSelected($event)" accept=".gpx" hidden>
          <label for="fileInput" class="upload-label">
            <img ngSrc="assets/images/backup-icon.svg" alt="Upload" height="24" width="24"/>
            <p>Tap to upload a GPX file</p>
          </label>
        </div>
        <div *ngIf="fileName" style="margin-top: -8px; margin-bottom: 16px; display: flex; gap: 8px">
          <span>{{ fileName }}</span>
          <button mat-icon-button (click)="onRemoveFile()" style="width: 24px; height: 24px; padding: 0">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
        <div *ngIf="fileErrorMessage" style="margin-top: -8px; margin-bottom: 16px">
          <mat-error>{{ fileErrorMessage }}</mat-error>
        </div>
      </div>
      <ng-template #manualAddTemplate>
        <div style="margin-top: 24px">
          <span class="input-label">Location (Approximate)</span>
          <div style="width: 100%; padding: 16px 0">
            <app-map-autocomplete placeholder="Ex. Irvine, CA" (addressSelected)="onAddressSelected($event)" [initialCoordinates]="{ lat: 41.437098, lng: 2.165957 }"></app-map-autocomplete>
          </div>
        </div>
        <div>
          <span class="input-label">Distance</span>
          <mat-form-field>
            <input matInput autocomplete="off" id="distance" formControlName="distance" placeholder="Ex. 26.2" mask="separator.2" pattern="^[0-9]*\.?[0-9]{0,2}$">
          </mat-form-field>
          <div *ngIf="raceForm.get('distance')?.hasError('pattern') && raceForm.get('date')?.touched" class="text-danger">
            <mat-error>Invalid date format</mat-error>
          </div>
        </div>
        <div>
          <span class="input-label">Measure unit</span>
          <mat-form-field>
            <mat-select id="unit" formControlName="unit">
              @for (unit of unitOptions; track unit) {
                <mat-option [value]="unit.value">{{unit.option}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <span class="input-label">Surface Type</span>
          <mat-form-field>
            <mat-select id="surface_type" formControlName="surface_type" placeholder="Select Surface Type">
              @for (surface of surfaceTypeOptions; track surface) {
                <mat-option [value]="surface.value">{{surface.option}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </ng-template>
      <app-custom-button type="submit" title="Upload Race" variant="primary" [disabled]="!raceForm.valid"></app-custom-button>
    </form>
  </div>
</div>
