import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatIconButton } from "@angular/material/button";
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbar } from "@angular/material/toolbar";
import { NgClass, NgIf, NgStyle } from "@angular/common";
import { RACE_ID, RACE_LOGO_URL, RACE_SITE_URL } from "../constants";
import { ScreenResizeService } from "./services/screenResive/screen-resize.service";
import { register } from 'swiper/element/bundle';
import { KeycloakService } from "keycloak-angular";
import { fromEvent, Subscription } from "rxjs";
import { OfflinePageComponent } from "./components/offline-page/offline-page.component";

register();

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [MatTabsModule, MatIconModule, RouterOutlet, MatIconButton, RouterLink, RouterLinkActive, MatToolbar, NgIf, NgStyle, NgClass, OfflinePageComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'neurun-fe';
  showBottomNav = true;
  raceUrl: string | null = null;
  isMobile: boolean = false;
  raceLogo: string = '';
  raceWebsite: string = '';
  currentYear: number = 0;
  showNavigation: boolean = true;
  subscriptions: Subscription[] = [];
  isUserOnline: boolean = true;
  currentUrl: string = ''

  constructor(
    private router: Router,
    private screenSizeService: ScreenResizeService,
    private readonly keycloakService: KeycloakService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private el: ElementRef
    ) {}

  async ngOnInit() {
    const raceId = sessionStorage.getItem(RACE_ID);

    const token = await this.keycloakService.getToken();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        this.showNavigation = !!token && this.currentUrl !== '/code-validation-error';
      }
    });

    this.preloadImage('assets/images/satellite.svg');

    this.currentYear = new Date().getFullYear();

    this.checkConnectionStatus();


    this.screenSizeService.getIsMobile().subscribe(isMobile => {
      this.isMobile = isMobile
    })

    if (raceId) {
      this.raceUrl = `/my-races/race/${raceId}`;
    } else {
      console.error('Race ID not found in local storage');
    }
    this.raceLogo = sessionStorage.getItem(RACE_LOGO_URL) || '';
    this.raceWebsite = localStorage.getItem(RACE_SITE_URL) || '';

    window.addEventListener('storage', (e) => this.onRaceLogoChange(e))
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const elements = document.querySelectorAll('[class*="api-load-alpha-banner"]');
      if (elements) {
        elements.forEach(element => {
          this.renderer.setStyle(element, 'display', 'none');
        });
      }
    }, 250)
  }

  preloadImage(url: string): void {
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = resolve
      img.onerror = reject
      img.src = url;
    })
  }

  checkConnectionStatus() {
    const onlineEvent = fromEvent(window, 'online');
    const offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(onlineEvent.subscribe(() => {
      this.isUserOnline = true;
    }));

    this.subscriptions.push(offlineEvent.subscribe(() => {
      this.isUserOnline = false;
    }));
  }

  onRaceLogoChange(event: StorageEvent) {
    if (event.storageArea === sessionStorage || event?.type === 'storage')   {
      if (event.key === RACE_LOGO_URL && event.newValue) {
        if (event.newValue) {
          this.raceLogo = event.newValue;
        }
      }
    }

    if (event.key === RACE_SITE_URL && event?.newValue) {
      if (event.newValue) {
        this.raceWebsite = event.newValue;
      }
    }
  }

  onRaceWebsiteClick() {
    if (this.raceWebsite) {
      window.open(this.raceWebsite, '_blank')
    }
  }

  ngOnDestroy() {
    window.removeEventListener('storage', this.onRaceLogoChange);
  }
}
