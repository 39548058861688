import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {DatePipe, NgClass, NgForOf, NgIf, NgStyle} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PromptsListComponent} from "../../components/prompts-list/prompts-list.component";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {ChatService} from "../../services/chat/chat.service";
import {
  ChatMessage,
  ChatMessageLink,
  GpxWaypoints,
  MappedChatMessage,
  Race, RaceRadiusData, SegmentTracks,
  Track,
  TrackCoordinates
} from "../../../types/models";
import {
  AI_REQUEST_TIMEOUT,
  AI_REQUESTS,
  CHOOSE_SEGMENT_OPTION,
  LINK_ACCOUNTS_IMAGES,
  RACE_ELEVATION_DATA,
  SET_RADIUS_OPTION,
  SHOW_MAP_OPTION
} from "../../../constants";
import {RaceService} from "../../services/race/race.service";
import {
  MatCell,
  MatCellDef, MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef, MatRow, MatRowDef, MatTable,
  MatTableDataSource
} from "@angular/material/table";
import {GoogleMapsServiceService} from "../../services/google-maps-service/google-maps-service.service";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {ElevationProfileComponent} from "../../components/elevation-profile/elevation-profile.component";
import {MapWithRadiusComponent} from "../../components/map-with-radius/map-with-radius.component";
import {MatChip, MatChipSet} from "@angular/material/chips";
import {RouteSwiperComponent} from "../../components/route-swiper/route-swiper.component";
import {RoutesMapComponent} from "../../components/routes-map/routes-map.component";
import {OidcServiceService} from "../../services/oidcService/oidc-service.service";

type Coordinates = {
  lat: number,
  lng: number
}

type MappedPathOutput = {
  lat: number,
  lng: number,
  distance?: number,
  elevationGain?: number,
  totalSimilarity?: number,
}

@Component({
  selector: 'app-ai-chat',
  standalone: true,
  imports: [
    NgIf,
    MatIcon,
    ReactiveFormsModule,
    FormsModule,
    PromptsListComponent,
    MatProgressSpinner,
    DatePipe,
    ElevationProfileComponent,
    MapWithRadiusComponent,
    MatCell,
    MatCellDef,
    MatChip,
    MatChipSet,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    NgForOf,
    RouteSwiperComponent,
    RoutesMapComponent,
    MatColumnDef,
    NgStyle,
    MatHeaderCellDef,
    NgClass
  ],
  templateUrl: './ai-chat.component.html',
  styleUrl: './ai-chat.component.scss'
})
export class AiChatComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  @Input() isOpen: boolean = false;
  @Input() prompt: string = '';
  @Input() token: string = '';
  @Input() elevations_data: TrackCoordinates[] | null = [];
  @Input() units: string = '';
  @Output() handleChangeDrawerState = new EventEmitter<boolean>();
  userMessage: string = '';
  prompts: string[] = ['Create a pacing plan', 'Recommend gear for event conditions', 'Assess my readiness for the race distance', 'Replicate uphill segments of the race near me', 'Help me find lodging near an event', ]
  raceId: string = 'dd4af909-924d-45aa-93ed-533339aff233';
  isChatLoading: boolean = false;
  messages: MappedChatMessage[] = [];
  elevationsData: TrackCoordinates[] | null = [];
  race: Race | null = null;
  start_point: Coordinates | null = null;
  race_units: string = '';
  radius_center_point: Coordinates | null = null;
  displayedColumns: string[] = ['weeks', 'runs_count', 'total_distance', 'longest_run'];
  newDataSource: MatTableDataSource<{ [key: string]: string }>
  isMessageLoading: boolean = false;
  segment_tracks: SegmentTracks[] | null = [];
  path: Coordinates[][] | null = null;
  isChatHistoryEmpty: boolean = false;
  AIRequestError: boolean = false;
  segment_coordinates: { startPointIndex: number, endPointIndex: number } | null = null;
  race_radius: number = 0;
  segment_distance: number = 0;
  hasRunkeeperTokenProcessed = false;
  private boundOnRunkeeperToken: (event: StorageEvent) => void;
  private loadingMessageTimeout: any = null;

  constructor(
    private chatService: ChatService,
    private sanitizer: DomSanitizer,
    private raceService: RaceService,
    private googleMapsService: GoogleMapsServiceService,
    private readonly oidcService: OidcServiceService,
    private cdr: ChangeDetectorRef
    ) {
    this.newDataSource = new MatTableDataSource<{ [key: string]: string }>([]);
    this.boundOnRunkeeperToken = this.onRunkeeperToken.bind(this);
  }

  ngOnInit() {
    window.addEventListener('storage', this.boundOnRunkeeperToken);
  }


  onRunkeeperToken(event: StorageEvent) {
    if (this.hasRunkeeperTokenProcessed) return;
    if (event.storageArea !== localStorage) return
    if ((event.key === 'runkeeper_token' || event.key === 'strava_token') && event.newValue) {
      const runkeeperData = JSON.parse(event.newValue);
      if (runkeeperData.token) {
        const accountType = event.key.replace('_token', '');
        this.chatService.sendLinkAccountMessage(runkeeperData.token, accountType);
        this.hasRunkeeperTokenProcessed = true;
      }
      window.removeEventListener('storage', this.onRunkeeperToken)
    }
  }

  ngOnDestroy() {
    window.removeEventListener('storage', this.boundOnRunkeeperToken);
    this.chatService.disconnect();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isOpen'] && this.isOpen) {
      this.initializeRaceId();
      console.log('HERE')
      if (this.raceId) {
        setTimeout(() => {
          this.loadRaceData();
        }, 1000)
      }
    }
  }

  private loadRaceData() {
    this.raceService.getRaceById(this.raceId!).subscribe((response) => {
      this.race = response;
      this.initializeChat();
    });
  }

  private initializeRaceId() {
    const elevationData = localStorage.getItem(RACE_ELEVATION_DATA) || '';
    // const parsedElevationData = elevationData && this.isMobile ? JSON.parse(elevationData) : [];

    this.elevationsData = [];

    if (this.elevations_data?.length) {
      this.start_point = { lat: this.elevations_data[0].lat, lng: this.elevations_data[0].lon };
    }

    if (this.elevations_data?.length) {
      this.elevationsData = this.elevations_data;
    }

    if (this.units) {
      this.race_units = this.units;
    }
  }

  private async initializeChat() {
    this.chatService.init(this.raceId!, this.token).then(() => {
      this.isChatLoading = true;
      this.chatService.onConnectionReady().subscribe(() => {
        this.isChatLoading = false;
        this.loadChatMessages();
        this.loadChatHistory();
        // this.subscribeToErrors()
      })
    })
  }

  private loadChatHistory() {
    if (this.prompt !== null) {
      this.chatService.getChatHistory().subscribe((data) => {
        this.processChatHistory(data);
      });
    }
  }

  private loadChatMessages() {
    this.chatService.getMessages().subscribe((message: ChatMessage) => {
      this.processIncomingMessage(message);
    });
  }

  private processChatHistory(data: ChatMessage[]) {
    if (data?.length) {
      this.isChatHistoryEmpty = false;
      const formattedMessages = this.formatChatHistory(data);
      this.messages.push(...formattedMessages);
    }
    this.sendInitialMessage();
    this.scrollToBottom();
  }

  private sendInitialMessage() {
    if (!AI_REQUESTS.includes(this.prompt)) {
      this.chatService.sendChangeScenarioMessage(AI_REQUESTS[0]);
      this.messages.push({ text: this.prompt, is_system: false, sent_at: new Date() });
      this.scrollToBottom();
      this.addLoadingMessage();
      setTimeout(() => {
        this.chatService.sendMessage(this.prompt);
        this.userMessage = '';
      }, 100)
    } else {
      this.chatService.sendMessage(this.prompt);

      this.messages.push({
        text: this.prompt,
        is_system: false,
        sent_at: new Date(),
      });
      this.scrollToBottom();
      this.addLoadingMessage();
    }
  }

  private addLoadingMessage() {
    this.isMessageLoading = true;
    this.messages.push({ text: '', is_system: true, loading: true, sent_at: new Date() });

    this.loadingMessageTimeout = setTimeout(() => {
      this.removeLoadingMessage();
      this.AIRequestError = true;
    }, AI_REQUEST_TIMEOUT);
  }

  private removeLoadingMessage() {
    const lastMessage = this.messages.find((msg) => msg.loading && msg.is_system);
    if (lastMessage) {
      this.messages = this.messages.filter((msg) => msg !== lastMessage);
    }

    if (this.loadingMessageTimeout) {
      clearTimeout(this.loadingMessageTimeout);
      this.loadingMessageTimeout = null;
    }
  }

  private processIncomingMessage(message: ChatMessage) {
    this.isChatLoading = false;
    console.log('MESS', message);
    if (this.loadingMessageTimeout) {
      clearTimeout(this.loadingMessageTimeout);
      this.loadingMessageTimeout = null;
    }

    const lastMessage = this.messages.find((msg) => msg.loading && msg.is_system);

    if (lastMessage) {
      this.updateLastMessage(lastMessage, message);
    } else {
      this.addNewMessage(message);
    }

    setTimeout(() => {
      this.scrollToBottom();
    }, 100)
  }

  scrollToBottom(): void {
    setTimeout(() => {
      this.chatContainer.nativeElement.scrollTo({
        top: this.chatContainer.nativeElement.scrollHeight,
        behavior: 'smooth',
      });
    }, 100);
  }

  private formatChatHistory(data: ChatMessage[]) {
    return data.flatMap((i) => {
      const baseMessage = {
        text: this.formatMessage(i.data?.message, i?.data?.links || []) || '',
        is_system: i.is_system,
        loading: false,
        sent_at: new Date(i.sent_at),
      };

      const gear = i?.data?.gears?.map((gear) => {
        const gearLinkText = { text: `Explore ${gear.brand}`, link: gear?.link };
        const gearLabel = gear.name;

        return {
          text: gearLabel,
          link: gearLinkText,
          image: gear?.images ? gear?.images[0]?.file_url : ''
        }
      })

      const gearMessage = [{
        text: '',
        label: '',
        sent_at: new Date(i.sent_at),
        gearOptions: gear,
        is_system: true
      }]
      return gearMessage ? [baseMessage, ...gearMessage] : baseMessage;
    });
  }

  private addNewMessage(message: ChatMessage) {
    this.messages.push({
      text: this.formatMessage(message.data?.message, message?.data?.links || []),
      is_system: true,
      sent_at: new Date(),
    });

    if (message?.data?.options) {
      console.log('OPT', message?.data?.options)
      this.messages.push({
        text: '',
        is_options_message: true,
        is_system: false,
        sent_at: new Date(),
        options: message.data?.options || [],
      })
    }
    if (message?.data?.widgets) {
      let options: string[] = [];

      if (message?.data?.widgets[0] === 'SEGMENT_PICKER') {
        options = [CHOOSE_SEGMENT_OPTION];
      }

      if (message?.data?.widgets[0] === 'RADIUS_PICKER' && !this.radius_center_point) {
        if (this.radius_center_point) {
          options = [SET_RADIUS_OPTION];
        } else {
          options = [SHOW_MAP_OPTION];
        }
      }

      if (options?.length && message?.data?.widgets[0] === 'RADIUS_PICKER' && options[0] !== SET_RADIUS_OPTION) {
        this.sendWidgetOption(options)
      }

      this.messages.push({
        text: '',
        is_system: true,
        sent_at: new Date(),
        widget: message?.data?.widgets[0]
      })

      if ((options?.length && message?.data?.widgets[0] === 'SEGMENT_PICKER') || options[0] === SET_RADIUS_OPTION) {
        this.sendWidgetOption(options)
      }
    }

    if (message?.data?.table?.length) {
      const table = this.convertTableToElementData(message?.data?.table)
      this.messages.push({
        text: '',
        is_options_message: true,
        is_system: true,
        sent_at: new Date(),
        table: table
      })
    }

    // if (message?.data?.widgets) {
    //   return this.messages.push({
    //     text: '',
    //     is_system: true,
    //     sent_at: new Date(),
    //     widget: message?.data?.widgets[0]
    //   })
    // }

    this.addGearMessages(message);
    this.addLinkAccountsMessage(message);
  }

  private addGearMessages(message: ChatMessage) {
    if (message.data?.gears?.length) {
      const gearMessage = message.data.gears.map((gear) => {
        const gearLinkText = { text: `Explore ${gear.brand}`, link: gear?.link };
        const gearLabel = gear.name;

        return {
          text: gearLabel,
          link: gearLinkText,
          image: gear?.images ? gear?.images[0]?.file_url : ''
        }
      })

      this.messages.push({
        text: '',
        label: '',
        sent_at: new Date(),
        gearOptions: gearMessage,
        is_system: true
      })
    }
  }

  private addLinkAccountsMessage(message: ChatMessage) {
    if (message.data?.link_accounts?.length) {
      const linkAccountsOptions = message.data.link_accounts.map(i => ({ name: i.name, logo: LINK_ACCOUNTS_IMAGES.find(j => j.name === i.name)?.image || '' }))
      this.messages.push({
        text: this.formatMessage(message.data.message, message?.data?.links || []),
        sent_at: new Date(),
        link_accounts_options: linkAccountsOptions,
        is_system: true
      })
    }
  }

  formatMessage(message: string, links: ChatMessageLink[]): SafeHtml {
    if (!message?.length) return '';

    let formattedMessage = message
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      .replace(/\n/g, '<br>')
      .replace(/### (.*?)(<br>|$)/g, '<h3>$1</h3>');

    formattedMessage = formattedMessage.replace(/\[P(\d+)\]/g, (match, pIndex) => {
      const link = links.find(l => l.reference === parseInt(pIndex, 10));

      return link ? `
        <a href="${link.url}" target="_blank" style="position: relative">
            <img
                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIEdlbmVyYXRvcjogU1ZHIFJlcG8gTWl4ZXIgVG9vbHMgLS0+DQo8c3ZnIHdpZHRoPSI4MDBweCIgaGVpZ2h0PSI4MDBweCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KPGcgaWQ9IkludGVyZmFjZSAvIEV4dGVybmFsX0xpbmsiPg0KPHBhdGggaWQ9IlZlY3RvciIgZD0iTTEwLjAwMDIgNUg4LjIwMDJDNy4wODAwOSA1IDYuNTE5NjIgNSA2LjA5MTggNS4yMTc5OUM1LjcxNTQ3IDUuNDA5NzMgNS40MDk3MyA1LjcxNTQ3IDUuMjE3OTkgNi4wOTE4QzUgNi41MTk2MiA1IDcuMDgwMDkgNSA4LjIwMDJWMTUuODAwMkM1IDE2LjkyMDMgNSAxNy40ODAxIDUuMjE3OTkgMTcuOTA3OUM1LjQwOTczIDE4LjI4NDIgNS43MTU0NyAxOC41OTA1IDYuMDkxOCAxOC43ODIyQzYuNTE5MiAxOSA3LjA3ODk5IDE5IDguMTk2OTEgMTlIMTUuODAzMUMxNi45MjEgMTkgMTcuNDggMTkgMTcuOTA3NCAxOC43ODIyQzE4LjI4MzcgMTguNTkwNSAxOC41OTA1IDE4LjI4MzkgMTguNzgyMiAxNy45MDc2QzE5IDE3LjQ4MDIgMTkgMTYuOTIxIDE5IDE1LjgwMzFWMTRNMjAgOVY0TTIwIDRIMTVNMjAgNEwxMyAxMSIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPg0KPC9nPg0KPC9zdmc+"
                alt="External link"
                style="width: 16px; height: 16px; position: absolute; top: 0;margin-left: 6px; filter: invert(43%) sepia(13%) saturate(4555%) hue-rotate(206deg) brightness(103%) contrast(104%)"
            />
        </a>` : match;
    });

    return this.sanitizer.bypassSecurityTrustHtml(formattedMessage);
  }

  convertTableToElementData(table: string[][]) {
    this.displayedColumns = table[0].map((header: string) => header);

    this.displayedColumns = table[0].map((header: string) => header);

    const rows = table.slice(1).map((row: string[]) => {
      const rowObj: { [key: string]: string } = {};
      row.forEach((cell: string, index: number) => {
        rowObj[this.displayedColumns[index]] = cell;
      });
      return rowObj;
    });

    if (this.newDataSource) {
      this.newDataSource.data = rows;
    }

    return rows
  }

  sendWidgetOption(options: string[]) {
    this.messages.push({
      text: '',
      is_options_message: true,
      is_system: false,
      sent_at: new Date(),
      is_widget_options: true,
      options
    })
  }

  private updateLastMessage(lastMessage: MappedChatMessage, message: ChatMessage) {
    this.isMessageLoading = false;
    lastMessage.loading = false;
    lastMessage.text = this.formatMessage(message.data?.message, message?.data?.links || []) || '';
    if (message.data?.options) {
      this.messages.push({
        text: '',
        is_options_message: true,
        is_system: false,
        sent_at: new Date(),
        options: message.data?.options || [],
      })
    }


    if (message.data?.gpx) {
      this.path = message?.data?.gpx.tracks.map(i => this.getMappedPath(i));

      if (this.path) {
        this.path.map((i: MappedPathOutput[], index) => {
          const photo = this.googleMapsService.getStreetViewPhotoUrl(i[0].lat, i[0].lng)
          this.segment_tracks?.push({
            photo_url: photo || '',
            segment: `Segment ${index + 1}`,
            elevationGain: i[0]?.elevationGain || 0,
            totalSimilarity: i[0]?.totalSimilarity || 0,
            distance: i[0].distance || 0,
          })
        })
      }

      this.messages.push({
        text: '',
        is_options_message: false,
        is_system: true,
        sent_at: new Date(),
        is_map: true,
        options: message.data?.options || [],
      })
    }

    if (message?.data?.widgets) {
      let options: string[] = [];

      if (message?.data?.widgets[0] === 'SEGMENT_PICKER') {
        options = [CHOOSE_SEGMENT_OPTION];
      }

      if (message?.data?.widgets[0] === 'RADIUS_PICKER') {
        if (this.radius_center_point) {
          options = [SET_RADIUS_OPTION];
        } else {
          options = [SHOW_MAP_OPTION];
        }
      }

      if (options?.length && message?.data?.widgets[0] === 'RADIUS_PICKER' && !this.radius_center_point) {
        this.sendWidgetOption(options)
      }
      this.messages.push({
        text: '',
        is_system: true,
        sent_at: new Date(),
        widget: message?.data?.widgets[0]
      })

      if ((options?.length && message?.data?.widgets[0] === 'SEGMENT_PICKER') || (message?.data?.widgets[0] === 'RADIUS_PICKER' && this.radius_center_point)) {
        this.sendWidgetOption(options)
      }
    }

    if (message?.data?.table?.length) {
      const table = this.convertTableToElementData(message?.data?.table)
      this.messages.push({
        text: '',
        is_options_message: true,
        is_system: true,
        sent_at: new Date(),
        table: table
      })
    }

    this.addGearMessages(message);
    this.addLinkAccountsMessage(message);
  }

  onFitnessClick(provider: string) {
    this.hasRunkeeperTokenProcessed = false;
    this.oidcService.openAuthWindow(provider);
  }

  getMappedPath(track: Track | GpxWaypoints): MappedPathOutput[] {
    if (track && track?.geometry && track?.properties) {
      const { distance, elevationGain, totalSimilarity } = track.properties;

      if (track?.properties?.distance && track?.properties?.elevationGain && track?.properties?.totalSimilarity) {
        return track.geometry.coordinates.map((i) => ({
          lat: i.lat,
          lng: i.lon,
          distance: Number(distance?.toFixed(1)) || 0,
          elevationGain: Number(elevationGain?.toFixed(0)) || 0,
          totalSimilarity: Number(totalSimilarity?.toFixed(0)) || 0
        }));
      } else {
        return track?.geometry?.coordinates.map(i => ({ lat: i.lat, lng: i.lon }))
      }
    } else {
      return [];
    }
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  onOptionClick(option: string) {
    if (option === CHOOSE_SEGMENT_OPTION || option === SHOW_MAP_OPTION || option === SET_RADIUS_OPTION) {
      if (option === CHOOSE_SEGMENT_OPTION && this.segment_coordinates) {
        this.chatService.sendSegmentCoordinatesMessage({ start_index: this.segment_coordinates?.startPointIndex, end_index: this.segment_coordinates?.endPointIndex, selected_distance: this.segment_distance });
      }

      if (option === SHOW_MAP_OPTION) {

        const lastMessageIndex = this.messages.length - 2;
        const lastMessage = this.messages[lastMessageIndex];

        if (lastMessage.options) {
          delete lastMessage.options;
          lastMessage.text = SHOW_MAP_OPTION
          lastMessage.is_options_message = false;
          lastMessage.is_widget_options = false;
        }

        this.messages[lastMessageIndex] = lastMessage;

        this.scrollToBottom();
        console.log('this.radius_center_point', this.radius_center_point);

        if (this.radius_center_point) {
          this.messages.push({
            text: '',
            is_options_message: true,
            is_system: false,
            sent_at: new Date(),
            is_widget_options: true,
            options: [SET_RADIUS_OPTION]
          })
          return;
        }
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.radius_center_point = { lat: position.coords.latitude, lng: position.coords.longitude }
            this.messages.push({
              text: '',
              is_options_message: true,
              is_system: false,
              sent_at: new Date(),
              is_widget_options: true,
              options: [SET_RADIUS_OPTION]
            })
          },
          (error) => {
            this.radius_center_point = { lat: this.start_point?.lat || 40.730610, lng: this.start_point?.lng || -73.935242 }
            this.messages.push({
              text: '',
              is_options_message: true,
              is_system: false,
              sent_at: new Date(),
              is_widget_options: true,
              options: [SET_RADIUS_OPTION]
            })
          },
          {
            enableHighAccuracy: true,
            maximumAge: 0
          }
        );
        return;
      }

      if (option === SET_RADIUS_OPTION && this.race_radius && this.radius_center_point) {
        const lastMessageIndex = this.messages.length - 1;

        const lastMessage = this.messages[lastMessageIndex];
        if (lastMessage.options) {
          delete lastMessage.options;
          lastMessage.text = SET_RADIUS_OPTION;
          lastMessage.is_options_message = false;
          lastMessage.is_widget_options = false;
        }

        this.messages[lastMessageIndex] = lastMessage;

        this.scrollToBottom();
        const data: RaceRadiusData = {
          radius: this.race_radius,
          point: { lat: this.radius_center_point.lat, lon: this.radius_center_point.lng }
        }
        this.chatService.sendMapRadiusMessage(data);
        this.addLoadingMessage();
        return;
      }
    }
    this.chatService.sendMessage(option);

    const lastMessageIndex = this.messages.length - 1;
    const lastMessage = this.messages[lastMessageIndex];

    if (lastMessage.options) {
      delete lastMessage.options;
    }

    this.messages[lastMessageIndex] = lastMessage;

    this.messages.push({ text: option, is_system: false, sent_at: new Date() });
    this.scrollToBottom();
    this.addLoadingMessage();
  }

  closeDrawer() {
    this.isOpen = false;
    this.handleChangeDrawerState.emit(false);
  }

  sendMessage() {
    if (this.userMessage.trim()) {
      this.chatService.sendChangeScenarioMessage(AI_REQUESTS[0]);
      this.messages.push({ text: this.userMessage.trim(), is_system: false, sent_at: new Date() });
      this.scrollToBottom();
      this.addLoadingMessage();
      setTimeout(() => {
        this.chatService.sendMessage(this.userMessage.trim());
        this.userMessage = '';
      }, 100)
    }
  }

  onSegmentSelected(event: { startPointIndex: number, endPointIndex: number, segmentDistance: number } | null) {
    if (event && event?.startPointIndex < event?.endPointIndex) {
      this.segment_coordinates = { startPointIndex: event.startPointIndex, endPointIndex: event.endPointIndex };
      this.segment_distance = event?.segmentDistance;
    } else {
      this.segment_coordinates = null;
    }
  }

  onRadiusSelect(event: { radius: number, center: Coordinates }) {
    this.race_radius = event?.radius
    this.radius_center_point = event?.center
  }

  onPromptClick(prompt: string) {
    this.chatService.sendMessage(prompt);
    this.messages.push({ text: prompt, is_system: false, sent_at: new Date() });
    this.scrollToBottom();
    this.addLoadingMessage();
  }
}
