<div style="width: 100%">
  <app-header title="Fitness Information" (handleBackClick)="onBackClick()"></app-header>
  <div *ngIf="spinnerService.visibility | async; else content" class="loading">
    <div class="loader"></div>
  </div>
  <ng-template #content>
    <div class="container" style="width: 100%">
      <div class="form-wrapper" [ngClass]="{'form-loading': isLoading}">
        <div *ngIf="isLoading" class="chat-loader-container">
          <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
        </div>
        <ng-container *ngIf="user">
          <form [formGroup]="fitnessForm" (ngSubmit)="onSubmit()">
            <div>
              <span class="input-label">Age <span class="required-field">*</span></span>
              <mat-form-field>
                <input matInput id="age" formControlName="age" autocomplete="off" mask="separator.0" [specialCharacters]="[]">
              </mat-form-field>
              <div *ngIf="fitnessForm.get('age')?.hasError('ageOutOfRange')">
                <mat-error>
                  Age must be between 12 and 100.
                </mat-error>
              </div>
            </div>
            <div>
              <span class="input-label">Gender <span class="required-field">*</span></span>
              <mat-form-field>
                <mat-select id="gender" formControlName="gender" placeholder="Select your gender">
                  @for (gender of genderOptions; track gender) {
                    <mat-option [value]="gender.value">{{gender.option}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div style="margin-top: 16px">
              <span class="input-label">About Me</span>
              <mat-form-field>
                <textarea
                  matInput
                  id="aboutMe"
                  formControlName="aboutMe"
                  placeholder=""
                  rows="5"
                ></textarea>
              </mat-form-field>
              <mat-error *ngIf="fitnessForm.get('aboutMe')?.hasError('maxlength')">
                Maximum length is 1000 characters
              </mat-error>
            </div>
            <div class="grid-container">
              <div style="display: grid; width: 50%">
                <span class="input-label">Height <span class="required-field">*</span></span>
                <mat-form-field>
                  <input matInput id="height" formControlName="height" autocomplete="off" [mask]="heightMask" [suffix]="userUnitSystem === 'imperial' ? '' : ' cm'">
                </mat-form-field>
                <div *ngIf="fitnessForm.get('height')?.hasError('heightOutOfRange')">
                  <mat-error>Height must be between
                    <ng-container *ngIf="userUnitSystem === 'metric'">100 and 250 cm</ng-container>
                    <ng-container *ngIf="userUnitSystem === 'imperial'">3'3" and 8'2"</ng-container>.
                  </mat-error>
                </div>
              </div>
              <div style="display: grid; width: 50%">
                <span class="input-label">Weight <span class="required-field">*</span></span>
                <mat-form-field>
                  <input matInput id="weight" formControlName="weight" autocomplete="off" mask="separator.2" [suffix]="userUnitSystem === 'imperial' ? ' lbs' : ' kg'">
                </mat-form-field>
                <div *ngIf="fitnessForm.get('weight')?.hasError('weightOutOfRange')">
                  <mat-error>Weight must be between
                    <ng-container *ngIf="userUnitSystem === 'metric'">40 and 250 kg</ng-container>
                    <ng-container *ngIf="userUnitSystem === 'imperial'">88 and 551 lbs</ng-container>.
                  </mat-error>
                </div>
              </div>
            </div>
            <div style="margin-top: 12px">
              <span class="input-label">Avg Resting Heart Rate</span>
              <mat-form-field>
                <input matInput id="avg_rest_hr" formControlName="avg_rest_hr" autocomplete="off" mask="separator.2" suffix=" bpm">
              </mat-form-field>
            </div>
            <div>
              <span class="input-label">Max Heart Rate</span>
              <mat-form-field>
                <input matInput id="max_hr" formControlName="max_hr" autocomplete="off" mask="separator.2" suffix=" bpm">
              </mat-form-field>
            </div>
            <div style="margin-bottom: 8px">
              <span class="input-label">VO<span style="font-size: 11px">2</span> Max</span>
              <mat-form-field>
                <input matInput id="vo_max" formControlName="vo_max" autocomplete="off" mask="separator.2">
              </mat-form-field>
            </div>
            <div style="height: 110px">
              <app-custom-button type="submit" title="Save" variant="primary" [disabled]="!fitnessForm.valid || !hasFormChanged()"></app-custom-button>
            </div>
          </form>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>
