<div class="page-container">
  <app-header title="AI Settings" (handleBackClick)="onBackClick()"></app-header>
  <div class="form-container" [ngClass]="{'form-loading': isLoading}">
    <div *ngIf="isLoading" class="chat-loader-container">
      <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
    </div>
    <ng-container *ngIf="user">
      <p class="section-title">Basic Settings</p>
      <div class="card">
        <!--      <div class="item">-->
        <!--        <div class="setting-description">-->
        <!--          <span class="subtitle">Voice</span>-->
        <!--          <div class="setting-subtitle">For audio playback</div>-->
        <!--        </div>-->
        <!--        <div style="width: 50%">-->
        <!--          <mat-select [(value)]="selectedVoice">-->
        <!--            <mat-option *ngFor="let voice of voices" [value]="voice">{{voice}}</mat-option>-->
        <!--          </mat-select>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--      <mat-divider></mat-divider>-->
        <div class="item">
          <div class="setting-description">
            <span class="subtitle">Language</span>
            <div class="setting-subtitle">Chat language</div>
          </div>
          <div style="width: 50%">
            <mat-select [(value)]="selectedLanguage">
              <mat-option *ngFor="let language of languages" [value]="language">{{language}}</mat-option>
            </mat-select>
          </div>
        </div>
      </div>
      <div style="margin-top: 24px">
        <p class="section-title">Advanced Settings</p>
        <div class="card">
          <div class="item">
            <div class="setting-description">
              <div>Memory</div>
              <div class="setting-subtitle">Remember interactions from other chats</div>
            </div>
            <div class="toggle-switch">
              <input type="checkbox" id="switch" [(ngModel)]="memoryEnabled" (change)="onToggle()" />
              <label for="switch">Toggle</label>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 24px">
        <p class="chat-window-btn" (click)="onDeleteChatHistoryClick()">Delete Chat History</p>
      </div>
      <div class="submit-button-container">
        <app-custom-button type="submit" title="Save" variant="primary" [disabled]="!hasFormChanged()" (click)="onSubmit()"></app-custom-button>
      </div>
    </ng-container>
  </div>
</div>
