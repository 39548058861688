import { Injectable } from '@angular/core';
import io, {Socket} from 'socket.io-client';
import {catchError, Observable, throwError} from 'rxjs';
import {KeycloakService} from "keycloak-angular";
import {
  ChatErrorMessage,
  ChatMessage,
  RaceRadiusData,
  RaceSegmentCoordinates, RaceSegmentCoordinatesIndex,
} from "../../../types/models";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private socket: Socket | null = null;
  private apiUrl: string = environment.wsUrl;
  private baseUrl: string = environment.baseUrl;

  constructor(private readonly keycloakService: KeycloakService, private http: HttpClient) {}

  async init(raceGuid: string | null) {
    const token = await this.keycloakService.getToken();
    if (token && raceGuid) {
      this.socket = io(`${this.apiUrl}?race_guid=${raceGuid}`, {
        transports: ['websocket'],
        path: '/api/ws/neurun',
        ackTimeout: 3 * 60 * 1000,
        auth: {
          token: `Bearer ${token}`
        }
      });

      this.socket.on('connect_error', () => {
        if (this.socket) {
          this.socket.off('ON_CONNECTION_READY', this.onConnectionReady);
          this.socket.off('SEND_MESSAGE', this.sendMessage);
        }
      })
    }
  }

  onConnectionReady() {
    return new Observable(observer => {
      if (this.socket) {
        this.socket.on('ON_CONNECTION_READY', () => {
          observer.next();
        });
        return () => {
          if (this.socket) {
            this.socket.disconnect();
          }
        };
      } else {
        throw Error('connection error')
      }
    })
  }

  sendMessage(message: string) {
    if (this.socket) {
      this.socket.volatile.emit('SEND_MESSAGE', {
        "type": "TEXT",
        "data": {
          "message": message
        }
      });
    }
  }

  sendLinkAccountMessage(token: string, accountType: string) {
    if (this.socket) {
      this.socket.volatile.emit('SEND_MESSAGE', {
        "type": "ACCOUNT_LINK",
        "data": {
          "token": token,
          "account_type": accountType
        }
      });
    }
  }

  sendSegmentCoordinatesMessage(message: RaceSegmentCoordinatesIndex) {
    if (this.socket) {
      this.socket.volatile.emit('SEND_MESSAGE', {
        "type": "RACE_SEGMENT",
        "data": message
      });
    }
  }

  sendMapRadiusMessage(message: RaceRadiusData) {
    if (this.socket) {
      this.socket.volatile.emit('SEND_MESSAGE', {
        "type": "SEARCH_RADIUS",
        "data": message
      });
    }
  }

  getMessages() {
    return new Observable<ChatMessage>(observer => {
      if (this.socket) {
        this.socket.on('ON_MESSAGE_RECEIVED', (data) => {
          observer.next(data);
        });
        return () => {
          if (this.socket) {
            this.socket.disconnect();
          }
        };
      } else {
        throw Error('get message error')
      }
    });
  }

  getChatHistory() {
    return new Observable<ChatMessage[]>(observer => {
      if (this.socket) {
        this.socket.on('ON_CHAT_HISTORY_RESTORE', (data) => {
          observer.next(data);
        });
        return () => {
          if (this.socket) {
            this.socket.disconnect();
          }
        };
      } else {
        throw Error('get chat history error')
      }
    })
  }

  getError() {
    return new Observable<ChatErrorMessage>(observer => {
      if (this.socket) {
        this.socket.on('ON_EVENT_ERROR', data => {
          observer.next(data);
        });
        return () => {
          if (this.socket) {
            this.socket.disconnect();
          }
        }
      } else {
        throw Error('get error error')
      }
    })
  }

  disconnect() {
    this.socket?.close();
    this.socket?.disconnect()
  }

  deleteChatHistory() {
    return this.http.delete(`${this.baseUrl}/user/chat/history`)
      .pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => errorMessage);
  }
}
