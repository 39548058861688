import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FindARaceComponent } from './pages/find-a-race/find-a-race.component';
import { MyRacesComponent } from './pages/my-races/my-races.component';
import { SavedComponent } from './pages/saved/saved.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AddNewRaceComponent } from "./pages/add-new-race/add-new-race.component";
import { AuthCallbackComponent } from "./pages/auth-callback/auth-callback.component";
import { SuccessRaceCreateComponent } from "./pages/success-race-create/success-race-create.component";
import { RaceComponent } from "./pages/race/race.component";
import { LoginComponent } from "./pages/login/login.component";
import { RegisterComponent } from "./pages/register/register.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { AppAuthGuard } from './app.authguard';
import { UserNameComponent } from "./pages/user-profile-sections/user-name/user-name.component";
import { UserEmailComponent } from "./pages/user-profile-sections/user-email/user-email.component";
import { UserFitnessComponent } from "./pages/user-profile-sections/user-fitness/user-fitness.component";
import { UserAccountsComponent } from "./pages/user-profile-sections/user-accounts/user-accounts.component";
import { UserAiSettingsComponent } from "./pages/user-profile-sections/user-ai-settings/user-ai-settings.component";
import { UserUnitsComponent } from "./pages/user-profile-sections/user-units/user-units.component";
import {
  UserNotificationsComponent
} from "./pages/user-profile-sections/user-notifications/user-notifications.component";
import { RaceAiChatComponent } from "./pages/race-ai-chat/race-ai-chat.component";
import { ValidationCodeComponent } from "./components/validation-code/validation-code.component";
import { FallbackPageComponent } from "./components/fallback-page/fallback-page.component";
import { UserErrorPageComponent } from "./components/user-error-page/user-error-page.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CacheInterceptor } from "./interceptors/cache-interceptor/cache-interceptor.interceptor";
import { SearchBarComponent } from "./web-components/search-bar/search-bar.component";

export const routes: Routes = [
  { path: '', redirectTo: '/my-races', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'find-a-race', component: FindARaceComponent },
  { path: 'my-races', component: MyRacesComponent },
  { path: 'saved', component: SavedComponent },
  { path: 'profile', component: ProfileComponent, canActivate: [AppAuthGuard] },
  { path: 'profile/user-name', component: UserNameComponent, canActivate: [AppAuthGuard] },
  { path: 'profile/user-email', component: UserEmailComponent, canActivate: [AppAuthGuard] },
  { path: 'profile/user-fitness', component: UserFitnessComponent, canActivate: [AppAuthGuard] },
  { path: 'profile/user-connected-accounts', component: UserAccountsComponent, canActivate: [AppAuthGuard] },
  { path: 'profile/user-ai-settings', component: UserAiSettingsComponent, canActivate: [AppAuthGuard] },
  { path: 'profile/user-units', component: UserUnitsComponent, canActivate: [AppAuthGuard] },
  { path: 'profile/user-notifications', component: UserNotificationsComponent, canActivate: [AppAuthGuard] },
  { path: 'my-races/race/:race_id', component: RaceComponent },
  { path: 'my-races/race/:race_id/ai-chat', component: RaceAiChatComponent },
  { path: 'my-races/add-race', component: AddNewRaceComponent, canActivate: [AppAuthGuard] },
  { path: 'auth-callback', component: AuthCallbackComponent, canActivate: [AppAuthGuard] },
  { path: 'add-new-race/success', component: SuccessRaceCreateComponent },
  { path: 'user-validation', component: ValidationCodeComponent },
  { path: 'code-validation-error', component: UserErrorPageComponent },
  { path: 'error-page', component: FallbackPageComponent },
  { path: 'search-bar', component: SearchBarComponent },
  { path: '**', component: FallbackPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AppAuthGuard],
  exports: [RouterModule]
})
@NgModule({
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }
  ]
})
export class AppRoutingModule { }
