import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-prompts-list',
  standalone: true,
  imports: [],
  templateUrl: './prompts-list.component.html',
  styleUrl: './prompts-list.component.scss'
})
export class PromptsListComponent {
  @Input() prompts: string[] = [];
  @Output() handlePromptClick = new EventEmitter<string>()

  onClick(prompt: string) {
    this.handlePromptClick.emit(prompt)
  }
}
