<swiper-container [slidesPerView]="1" [spaceBetween]="12" [centeredSlides]="true" [centeredSlidesBounds]="true" [width]="340">
  <ng-container *ngFor="let slide of routes">
    <swiper-slide>
      <div style="display: flex">
        <div style="display: contents">
          <img [src]="slide.photo_url" class="route-image" alt="Segment image" />
        </div>
        <div style="display: flex; flex-direction: column; margin: 8px; gap: 6px">
          <span style="font-size: 14px; font-weight: 700">{{ slide.segment }}</span>
          <span>
            <p class="details"><strong>{{ slide?.distance }} {{ units === 'imperial' ? 'miles' : 'km'}}</strong></p>
            <p class="details"><strong>{{ slide?.elevationGain }} {{ units === 'imperial' ? 'ft' : 'm'}}</strong> total elevation gain</p>
            <p class="details"><strong>{{ slide?.totalSimilarity }}%</strong> match in elevation gain</p>
          </span>
        </div>
      </div>
    </swiper-slide>
  </ng-container>
</swiper-container>
