<div class="map-container">
  <div style="border: 1px solid #ccc; margin-bottom: -2px">
    <div>
      <google-map
        height="400px"
        width="100%"
        [center]="circleCenter || {lat: 0, lng: 0}"
        [zoom]="zoom"
        [options]="mapOptions"
        (mapClick)="onMapClick($event)"
      >
        <div *ngIf="circleOptions.center && circleOptions.radius">
          <map-circle
            [center]="circleOptions.center"
            [radius]="circleOptions.radius"
            [options]="circleOptions"
          ></map-circle>
        </div>
        <map-marker *ngIf="circleCenter" [options]="markerOptions"></map-marker>
      </google-map>
    </div>
  </div>
  <div class="radius-selector-container">
    <div class="radius-switcher-container">
      <button
        (click)="decrementRadius()"
        class="adjust-button minus-button"
        [disabled]="selectedRadius === minRadius">
        -
      </button>
      <span class="radius-title">Segment Radius</span>
      <button
        (click)="incrementRadius()"
        class="adjust-button plus-button"
        [disabled]="selectedRadius === maxRadius">
        +
      </button>
    </div>
    <div class="radius-value">
      <span>Within {{ selectedRadius }} {{ ( userUnit === 'metric' ? 'km' : 'mi' ) }}</span>
    </div>
  </div>
</div>
