<div style="padding: 16px">
  <div class="container">
    <img
      ngSrc="assets/images/neurun-logo.svg"
      alt="Satellite"
      height="26" width="138"
    />
    <p style="font-weight: 600; font-size: 17px; text-align: center; margin-top: 32px">We are sorry</p>
    <p style="font-weight: 400; font-size: 15px; text-align: center; margin-bottom: 36px">The provided code has already been used.</p>
  </div>
</div>

