import {AfterViewInit, ChangeDetectorRef, Component, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {MatFormField, MatPrefix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AiChatComponent} from "../ai-chat/ai-chat.component";
import {PromptsListComponent} from "../../components/prompts-list/prompts-list.component";
import {NgIf} from "@angular/common";
import {KeycloakService} from "keycloak-angular";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {RACE_LOGO_URL, RACE_SITE_URL, USER_UNIT_SYSTEM} from "../../../constants";
import {RaceService} from "../../services/race/race.service";
import {GpxWaypoints, Race, Track, TrackCoordinates} from "../../../types/models";
import {AuthDrawerComponent} from "../auth-drawer/auth-drawer.component";
import {KeycloakProfile} from "keycloak-js";
import {BehaviorSubject} from "rxjs";

type Coordinates = {
  lat: number,
  lng: number
}

@Component({
  selector: 'app-search-bar',
  standalone: true,
  imports: [
    MatFormField,
    MatIcon,
    MatInput,
    MatPrefix,
    ReactiveFormsModule,
    FormsModule,
    AiChatComponent,
    PromptsListComponent,
    NgIf,
    AuthDrawerComponent
  ],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
  // providers: [KeycloakService],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class SearchBarComponent implements OnInit, AfterViewInit {
  isDrawerOpen = false;
  prompts: string[] = ['Create a pacing plan', 'Recommend gear for event conditions', 'Assess my readiness for the race distance', 'Replicate uphill segments of the race near me', 'Help me find lodging near an event', ]
  selectedPrompt: string = '';
  keycloakLoginUrl: SafeResourceUrl = '';
  showLoginIframe: boolean = false;
  raceId: string = 'dd4af909-924d-45aa-93ed-533339aff233';
  race: Race | null = null;
  raceUnit: string = '';
  start_point: Coordinates | null = null;
  end_point: Coordinates | null = null;
  path: Coordinates[] | null = null;
  waypoints: GpxWaypoints[] | null = null;
  elevationsData: TrackCoordinates[] | null = [];
  userUnitSystem: string = 'imperial';
  userMessage: string = '';
  isAIDrawerOpen: boolean = false;
  token: string = '';

  // @ts-ignore
  private userSubject$ = new BehaviorSubject<KeycloakProfile>(null);


  constructor(
    private keycloakService: KeycloakService,
    private sanitizer: DomSanitizer,
    private raceService: RaceService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {}

  async ngOnInit() {
    this.checkUserAuth();
    await this.getRace();
    this.loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyDsoZfoY75xr-6bUPgVNrsgFsJ59u0Yohw&libraries&v=alpha&libraries=places,geometry,maps3d,marker')

    window.addEventListener('storage', (e) => this.onAuthStateChange(e))
  }

  loadScript(src: string) {
    const script = document.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.async = true;


    document.head.appendChild(script);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const elements = document.querySelectorAll('[class*="api-load-alpha-banner"]');
      if (elements) {
        elements.forEach(element => {
          this.renderer.setStyle(element, 'display', 'none');
        });
      }
    }, 250)
  }

  async onAuthStateChange(event: StorageEvent) {
    if (event.storageArea === localStorage || event?.type === 'storage')   {
      if (event.key === 'success-login' && event.newValue) {
        if (event.newValue) {
          this.showLoginIframe = false;
          this.keycloakLoginUrl = '';
          this.isDrawerOpen = false;
          this.cdr.detectChanges();
          this.token = event.newValue;
          this.isAIDrawerOpen = true
        }
      }
    }
  }

  async checkUserAuth() {
    try {
      const isUserLoggedIn = this.keycloakService.isLoggedIn();
      if (!isUserLoggedIn) {
        const unsafeUrl = this.keycloakService.getKeycloakInstance().createLoginUrl();
        this.keycloakLoginUrl = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
        this.showLoginIframe = true;
      } else {
        const token = await this.keycloakService.getToken();
        localStorage.setItem('success-login', token);
        this.showLoginIframe = false;
        this.keycloakLoginUrl = '';
        this.isDrawerOpen = false;
        this.cdr.detectChanges();
      }
    } catch (error) {
      console.error('Error checking user login status:', error);
      const unsafeUrl = this.keycloakService.getKeycloakInstance().createLoginUrl();
      this.keycloakLoginUrl = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
      this.showLoginIframe = true;
    }
  }

  closeLoginIframe() {
    const iframe = document.getElementById('login-iframe');
    if (iframe) {
      iframe.remove();
    }
  }

  async getRace() {
    if (this.raceId) {
      this.raceService.getRaceById(this.raceId).subscribe({
        next: (response) => {
          this.race = response ? { ...response, starting_at: response?.starting_at.slice(0, -1) } : null;
          if (this.race?.guid) {
            this.getGpxData(this.race?.guid)
          }

          this.raceUnit = response?.units;

          const userUnitSystem = localStorage.getItem(USER_UNIT_SYSTEM);
          this.userUnitSystem = (userUnitSystem && JSON.parse(userUnitSystem)) || response?.units;

          if (response?.logo?.file_url) {
            sessionStorage.setItem(RACE_LOGO_URL, response?.logo?.file_url);

            window.dispatchEvent(new StorageEvent('storage', {
              key: RACE_LOGO_URL,
              newValue: this.race?.logo?.file_url
            }));
          } else {
            sessionStorage.removeItem(RACE_LOGO_URL);
          }

          if (response?.website_url) {
            localStorage.setItem(RACE_SITE_URL, response?.website_url);

            window.dispatchEvent(new StorageEvent('storage', {
              key: RACE_SITE_URL,
              newValue: this.race?.website_url
            }));
          } else {
            localStorage.removeItem(RACE_SITE_URL);
          }

        },
      })
    }
  }

  getGpxData(guid: string) {
    this.raceService.getGpxData(guid).subscribe(response => {
      if (response?.start_point && response?.end_point) {
        if (response?.start_point.lat && response?.start_point.lon) {
          this.start_point = {lat: response.start_point.lat, lng: response.start_point.lon};
          this.path = this.getMappedPath(response.tracks[0])
          this.waypoints = response.waypoints
        }
        if (response?.end_point.lat && response?.end_point.lon) {
          this.end_point = {lat: response.end_point.lat, lng: response.end_point.lon};
        }

        this.elevationsData = response.tracks[0].geometry.coordinates
      }
    })
  }

  getMappedPath(track: Track | GpxWaypoints) {
    if (track) {
      return track?.geometry?.coordinates.map(i => ({ lat: i.lat, lng: i.lon }))
    } else {
      return []
    }
  }

  sendMessage() {
    if (this.userMessage.trim()) {
      this.openDrawer(this.userMessage)
      this.userMessage = '';
    }
  }

  handleChangeDrawerState(isOpen: boolean) {
    this.isDrawerOpen = isOpen
    if (this.showLoginIframe) {
      this.isDrawerOpen = isOpen
    } else {
      this.isAIDrawerOpen = isOpen
    }
  }

  openDrawer(prompt: string) {
    this.selectedPrompt = prompt;
    if (this.showLoginIframe) {
      this.isDrawerOpen = !this.isDrawerOpen
    } else {
      this.isAIDrawerOpen = !this.isAIDrawerOpen
    }
  }
}
