import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { NgForOf } from "@angular/common";
import { SegmentTracks } from "../../../types/models";

@Component({
  selector: 'app-route-swiper',
  standalone: true,
  imports: [
    NgForOf
  ],
  templateUrl: './route-swiper.component.html',
  styleUrl: './route-swiper.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RouteSwiperComponent {
  @Input() routes: SegmentTracks[] | null = []
  @Input() units: string = 'metric';

  constructor() {}
}
