<div class="drawer-overlay" *ngIf="isOpen" (click)="closeDrawer()"></div>
<div class="drawer" [class.open]="isOpen">
  <div class="header">
    <span class="drawer-title">
      AI Race Concierge
    </span>
    <span style="cursor: pointer" (click)="closeDrawer()">
        <img
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzU4M18xMTYwIiBzdHlsZT0ibWFzay10eXBlOmFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMzQiIGhlaWdodD0iMzQiPgo8cmVjdCB3aWR0aD0iMzQiIGhlaWdodD0iMzQiIGZpbGw9IiNEOUQ5RDkiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swXzU4M18xMTYwKSI+CjxwYXRoIGQ9Ik04LjUzMzQyIDI0Ljg3MTZMNy4xMjg0MiAyMy40NjY2TDE0LjU5NTEgMTZMNy4xMjg0MiA4LjUzMzNMOC41MzM0MiA3LjEyODNMMTYuMDAwMSAxNC41OTVMMjMuNDY2OCA3LjEyODNMMjQuODcxOCA4LjUzMzNMMTcuNDA1MSAxNkwyNC44NzE4IDIzLjQ2NjZMMjMuNDY2OCAyNC44NzE2TDE2LjAwMDEgMTcuNDA1TDguNTMzNDIgMjQuODcxNloiIGZpbGw9IiM2MjcxRkYiLz4KPC9nPgo8L3N2Zz4K"
          alt="Close"
          style="cursor: pointer"
        />
    </span>
  </div>
  <div class="chat-wrapper">
    <div #chatContainer class="chat-container">
      <div *ngIf="isChatLoading" class="chat-loader-container">
        <div class="custom-loader"></div>
      </div>

      <ng-container *ngFor="let message of messages; let i = index">
        <div *ngIf="i === 0 || !isSameDay(messages[i-1].sent_at, message.sent_at)" class="date">
          {{ message.sent_at | date: 'MMMM d, yyyy' }}
        </div>
        <div *ngIf="message?.link_accounts_options && message?.link_accounts_options?.length; else defaultTemplate" class="fitness-wrapper">
          <mat-chip-set>
            @for (option of message?.link_accounts_options; track option) {
              <mat-chip
                class="fitness-container"
                (click)="onFitnessClick(option.name)"
              >
                <img [src]="option.logo" alt="logo" style="min-height: 25px" />
              </mat-chip>
            }
          </mat-chip-set>
        </div>
        <div *ngIf="message?.table && message?.table!.length >= 1" style="margin: 16px 0">
          <div class="table-responsive">
            <div class="mat-elevation-z8">
              <table mat-table [dataSource]="newDataSource" class="mat-table">

                <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
                  <th mat-header-cell *matHeaderCellDef
                      [ngStyle]="{
                        'min-width': column === 'Race Km' || column === 'Race Mile' ? '225px' :
                        column === 'Week' ? '150px' : '100px'
                   }">
                    {{ column }}
                  </th>
                  <td mat-cell *matCellDef="let element"> {{ element[column] }} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
        </div>
        <ng-template #defaultTemplate>
          <div class="chat-message" [ngClass]="{'ai-message': message.is_system, 'user-message': !message.is_system}">
            <div
              [ngClass]="{'message-content': !message?.is_options_message && !message?.widget && !message.is_map && (message?.text || message?.label), 'option-message': message?.is_options_message, 'map-message': message?.is_map}"
              style="overflow-y: auto"
            >
              <p *ngIf="message?.label && message.text" style="font-weight: 700">{{ message?.label }}</p>
              <div [innerHTML]="message.text"></div>
              <div *ngIf="message?.options?.length && message?.is_options_message" class="link-container">
                <mat-chip-set class="chip-container">
                  @for (option of message?.options; track option) {
                    <mat-chip class="option-container" (click)="onOptionClick(option)" [disabled]="option === 'Choose This Segment' && !segment_coordinates">
                      <span>{{ option }}</span>
                    </mat-chip>
                  }
                </mat-chip-set>
              </div>
              <div *ngIf="message?.gearOptions?.length" class="gear-options-container">
                @for (option of message?.gearOptions; track option) {
                  <div class="message-content" style="min-width: 180px; min-height: 100px; display: flex; flex-direction: column; justify-content: space-between; padding: 0">
                    <div *ngIf="option?.image">
                      <img [src]="option?.image" style="width: 180px; height: 185px; border-top-left-radius: 15px; border-top-right-radius: 15px; object-fit: cover" [alt]="option?.text" />
                    </div>
                    <div style="padding: 10px">
                      <p style="font-weight: 700; margin: 2px 0; font-size: 16px">{{ option.text }}</p>
                      <div class="link-container">
                        <a [href]='option?.link?.link' class="link" target="_blank" rel="noopener noreferrer">
                          {{ option?.link?.text }}
                          <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzY3M18xMjA1IiBzdHlsZT0ibWFzay10eXBlOmFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjUiIGhlaWdodD0iMjUiPgo8cmVjdCB4PSIwLjI2MjY5NSIgeT0iMC4zNTU5NTciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iI0Q5RDlEOSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfNjczXzEyMDUpIj4KPHBhdGggZD0iTTE2Ljg4OTcgMTMuMTA2SDQuNzYyN1YxMS42MDZIMTYuODg5N0wxMS4xOTM0IDUuOTA5NzFMMTIuMjYyNyA0Ljg1NTk2TDE5Ljc2MjcgMTIuMzU2TDEyLjI2MjcgMTkuODU2TDExLjE5MzQgMTguODAyMkwxNi44ODk3IDEzLjEwNloiIGZpbGw9IiM2MjcxRkYiLz4KPC9nPgo8L3N2Zz4K" alt="Arrow forward" />
                        </a>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div *ngIf="message.is_map && path && segment_tracks">
                <div style="position: relative; overflow: hidden">
                  <app-routes-map [paths]="path"></app-routes-map>
                  <div style="position: absolute; bottom: 10px">
                    <app-route-swiper [routes]="segment_tracks" [units]="race_units"></app-route-swiper>
                  </div>
                </div>
              </div>
              <div *ngIf="message.loading" class="three-dots-loader">
                <span></span><span></span><span></span>
              </div>
            </div>
          </div>
        </ng-template>
        <div *ngIf="message?.widget">
          <div *ngIf="message.widget === 'SEGMENT_PICKER' && elevationsData?.length">
            <app-elevation-profile [coordinates]="elevationsData" [showSelection]="true" (handleSegmentSelected)="onSegmentSelected($event)" [units]="race_units"></app-elevation-profile>
          </div>
          <div *ngIf="message.widget === 'RADIUS_PICKER' && radius_center_point" style="margin-bottom: 10px">
            <app-map-with-radius [startPoint]="radius_center_point || start_point" (handleSelectRadius)="onRadiusSelect($event)" [userUnit]="race_units"></app-map-with-radius>
          </div>
        </div>
      </ng-container>

    </div>
    <div class="input-container">
      <input
        class="chat-input"
        [(ngModel)]="userMessage"
        placeholder="Ask a follow up question"
        (keydown.enter)="sendMessage()"
      >
      <button (click)="sendMessage()" class="send-btn" [disabled]="!userMessage.trim()">
        <img
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzU4M18xMjcxIiBzdHlsZT0ibWFzay10eXBlOmFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjUiIGhlaWdodD0iMjUiPgo8cmVjdCB4PSIwLjM1NTQ2OSIgeT0iMjQuNzM3MiIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB0cmFuc2Zvcm09InJvdGF0ZSgtOTAgMC4zNTU0NjkgMjQuNzM3MikiIGZpbGw9IiNEOUQ5RDkiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swXzU4M18xMjcxKSI+CjxwYXRoIGQ9Ik0xMy4xMDU1IDguMTEwMThMMTMuMTA1NSAyMC4yMzcyTDExLjYwNTUgMjAuMjM3MkwxMS42MDU1IDguMTEwMThMNS45MDkyMiAxMy44MDY0TDQuODU1NDcgMTIuNzM3MkwxMi4zNTU1IDUuMjM3MThMMTkuODU1NSAxMi43MzcyTDE4LjgwMTcgMTMuODA2NEwxMy4xMDU1IDguMTEwMThaIiBmaWxsPSIjN0U3RTdFIi8+CjwvZz4KPC9zdmc+Cg=="
          alt="Send icon"
        />
      </button>
    </div>
    <div class="additional-prompts">
      <span style="font-weight: 400; font-size: 16px; color: #FFFFFF; white-space: nowrap; line-height: 22px">
        Additional prompts:
      </span>
      <app-prompts-list [prompts]="prompts" (handlePromptClick)="onPromptClick($event)"></app-prompts-list>
    </div>
  </div>
</div>
