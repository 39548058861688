import { bootstrapApplication, createApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { createCustomElement } from "@angular/elements";
import { SearchBarComponent } from "./app/web-components/search-bar/search-bar.component";
import { KeycloakBearerInterceptor, KeycloakService} from "keycloak-angular";
import { keycloakOptions } from "./keycloak.config";
import { APP_INITIALIZER, Provider } from "@angular/core";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { provideRouter } from "@angular/router";
import { routes } from "./app/app.routes";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { provideEnvironmentNgxMask } from "ngx-mask";
import { provideOAuthClient } from "angular-oauth2-oidc";
import { AuthInterceptor } from "./app/interceptors/http-interceptor/auth.interceptor";

bootstrapApplication(AppComponent, appConfig)
.catch((err) => console.error(err));

function initializeKeycloak(keycloak: KeycloakService) {
  return () => keycloak.init(keycloakOptions);
}

const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true
};

const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  multi: true,
  deps: [KeycloakService]
};

const SpinnerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true
};

(async () => {
  const app = await createApplication({
    providers: [
      provideRouter(routes),
      provideAnimationsAsync(),
      provideHttpClient(withInterceptorsFromDi()),
      provideEnvironmentNgxMask(),
      KeycloakBearerInterceptorProvider,
      KeycloakInitializerProvider,
      KeycloakService,
      SpinnerInterceptorProvider,
      provideOAuthClient({
        resourceServer: {
          sendAccessToken: true
        }
      })
    ]
  });
  const searchBarElement = createCustomElement(SearchBarComponent, { injector: app.injector });
  customElements.define('search-bar', searchBarElement);
})();
