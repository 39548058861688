import {Component, OnInit} from '@angular/core';
import {AsyncPipe, NgClass, NgIf, NgOptimizedImage} from "@angular/common";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatError, MatFormField } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { NgxMaskDirective } from "ngx-mask";
import { CustomButtonComponent } from "../custom-button/custom-button.component";
import { UserService } from "../../services/user/user.service";
import { KeycloakService } from "keycloak-angular";
import { SpinnerServiceService } from "../../services/spinner-service/spinner-service.service";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { ACCESS_CODE, RACE_ID } from "../../../constants";

@Component({
  selector: 'app-validation-code',
  standalone: true,
  imports: [
    NgOptimizedImage,
    FormsModule,
    MatError,
    MatFormField,
    MatInput,
    NgIf,
    NgxMaskDirective,
    ReactiveFormsModule,
    CustomButtonComponent,
    AsyncPipe,
    NgClass,
    MatProgressSpinner
  ],
  templateUrl: './validation-code.component.html',
  styleUrl: './validation-code.component.scss'
})
export class ValidationCodeComponent implements OnInit {
  public customPatterns = { 'A': { pattern: new RegExp('[a-zA-Z0-9]') } };
  public errorMessage: string = '';
  public isLoading: boolean = false;

  constructor(private userService: UserService, private readonly keycloakService: KeycloakService, public spinnerService: SpinnerServiceService) {
  }

  accessCodeForm = new FormGroup({
    code: new FormControl('', [Validators.required, Validators.minLength(4)])
  })

  ngOnInit() {
    const code = localStorage.getItem(ACCESS_CODE);
    this.accessCodeForm.get('code')?.setValue(code);
  }

  storeCodeInLocalStorage(code: string) {
    localStorage.setItem(ACCESS_CODE, code);
  }

  onSubmit() {
    if (this.accessCodeForm.valid && this.accessCodeForm.value.code && this.accessCodeForm.value.code?.length === 4) {
      this.isLoading = true;
      const code = this.accessCodeForm.value.code;
      this.userService.checkAccessCode(code).subscribe((res) => {

        const raceId = sessionStorage.getItem(RACE_ID);
        const redirectUri = raceId ? `${window.location.origin}/my-races/race/${raceId}` : `${window.location.origin}/profile`;

        if (!res?.is_valid) {
          this.errorMessage = 'Code is invalid, please try again.';
        }
        if (res?.is_valid && !res?.is_used) {
          this.storeCodeInLocalStorage(code);
          this.keycloakService.register({ redirectUri });
        }

        if (res?.is_valid && res?.is_used) {
          this.storeCodeInLocalStorage(code);
          this.keycloakService.login({ redirectUri });
        }

        this.isLoading = false;
      });
    }
  }
}
