import { Component } from '@angular/core';
import { CustomButtonComponent } from "../custom-button/custom-button.component";

@Component({
  selector: 'app-offline-page',
  standalone: true,
  imports: [
    CustomButtonComponent
  ],
  templateUrl: './offline-page.component.html',
  styleUrl: './offline-page.component.scss'
})
export class OfflinePageComponent {
  constructor() {}

  onPageReload() {
    window.location.reload()
  }
}
