<div class="container-wrapper">
  <div class="container">
    <div class="header">
      <div class="header-content-wrapper">
        <div class="header-text">
          Welcome to Race Roster’s AI Race Concierge Powered by Neurun
        </div>
        <div class="logos-container">
          <img src="assets/images/rr-logo.svg" />
          <img src="assets/images/neurun-logo.svg" width="104" height="20"  />
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center;" [ngClass]="{'form-loading': isLoading}">
      <div *ngIf="isLoading" class="chat-loader-container">
        <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
      </div>
      <div *ngIf="!isLoading" class="form-container">
        <form [formGroup]="accessCodeForm" (ngSubmit)="onSubmit()">
          <span class="input-label">Access Code</span>
          <span class="subtitle">
          Please enter the access code that was sent to your email.
        </span>
          <mat-form-field>
            <input matInput id="code" formControlName="code" placeholder="Access Code" autocomplete="off" mask="AAAA" [patterns]="customPatterns">
          </mat-form-field>

          <div *ngIf="errorMessage">
            <mat-error>
              {{ errorMessage }}
            </mat-error>
          </div>

          <div style="text-align: center" class="footer-info-text">
            By continuing, you agree to Neurun’s <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=b084bde0-aebd-4b00-a31a-c02f05e839a8" target="_blank" style="color: #6271FF">terms and conditions</a>. Your messages with our race concierge may be used to improve our experience for all event participants.
          </div>

          <div style="height: 110px">
            <app-custom-button type="submit" title="Continue" variant="primary" [disabled]="!accessCodeForm.valid"></app-custom-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
