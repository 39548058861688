<span *ngIf="isMobile">
    <app-header
      title="Race Details"
      (handleBackClick)="onBackClick()"
      [showAdditionalAction]="!isEdit"
      type="edit"
      [isEditMode]="!isEdit"
      (handleRightButtonClick)="onEditClick()"
      [whiteHeader]="true"
      [siteLogo]="race?.logo?.file_url"
      [imageHeight]="imageHeight"
    ></app-header>
  </span>
<div class="container" *ngIf="race; else noRaceTemplate" (scroll)="onScroll($event)">
  <div *ngIf="isMobile; else desktopView">
    <!--    <div>-->
    <!--      <img [src]="race_image || 'assets/images/default-image.png'" alt="Race image" class="race-image" />-->
    <!--    </div>-->

    <div style="padding: 32px 24px">
      <p class="race-name">{{race.name}}</p>
      <div style="display: flex; gap: 4px; margin-top: 16px">
          <span class="race-info-box">
            <img ngSrc="assets/images/surface.svg" alt="Surface" height="12" width="12"/>
            <span class="race-info-title">{{ race.surface_type | titlecase }}</span>
          </span>
        <span class="race-info-box">
            <img ngSrc="assets/images/distance_run.svg" alt="Distance" height="12" width="12"/>
            <span class="race-info-title">{{ race.distance }} {{ raceUnit === 'metric' ? 'km' : 'mi' }}</span>
          </span>
      </div>

      <div style="margin: 12px 0">
        <span style="font-weight: 500; font-size: 16px; color: #0B0B0B; line-height: 8px">
          {{race.starting_at | date:time_format}}, {{ race.starting_at | date:date_format }}
          <p style="margin-top: 8px; line-height: 19px;">
            {{ race.location }}
          </p>
        </span>
        <div style="margin-top: 12px; font-weight: 500; font-size: 16px; color: #0B0B0B" *ngIf="temperature">
          <span *ngIf="userUnitSystem === raceUnit; else convertedTemperatureTemplate">
            {{ temperature | number: '1.0-0' }}°{{raceUnit === 'metric' ? 'C' : 'F'}} <span style="color: #7E7E7E">- Avg. {{race.starting_at | date:time_format}} temp</span>
          </span>
          <ng-template #convertedTemperatureTemplate>
            {{ temperature | temperatureConverter:userUnitSystem }} <span style="color: #7E7E7E">- Avg. {{race.starting_at | date:time_format}} temp</span>
          </ng-template>
        </div>
      </div>

      <div *ngIf="race.registration_url" style="margin-top: 24px">
        <app-custom-button title="Register" variant="primary" (click)="onLinkClick(race.registration_url)"></app-custom-button>
      </div>

      <div *ngIf="race.name.startsWith('California International Marathon')" class="ai-overview-container" style="text-align: center; margin-top: 16px">
        <span class="action-button" style="cursor: default" >AI Podcast Course Overview</span>
        <div style="display: flex; align-items: center; gap: 20px">
          <img src="assets/images/spotify-logo.svg" alt="Spotify" (click)="onRaceOverviewClick('spotify')" class="ai-overview-icon" />
          <img src="assets/images/youtube-logo.svg" alt="YouTube" (click)="onRaceOverviewClick('youtube')" class="ai-overview-icon" />
          <img src="assets/images/apple-podcasts-logo.svg" alt="Apple Podcasts" (click)="onRaceOverviewClick('applePodcast')" class="ai-overview-icon" />
        </div>
      </div>

      <div class="divider-wrapper">
        <mat-divider></mat-divider>
      </div>

      <div style="margin: 32px 0">
        <div style="margin: 32px 0">
          <div style="display: flex">
            <img ngSrc="assets/images/chat_bubble.svg" alt="AI Chat" height="24" width="24" style="margin-right: 8px"/>
            <span class="section-title">Ask our AI Race Concierge:</span>
          </div>
          <div style="margin-top: 12px">
            <mat-chip-set>
              @for (request of AI_REQUESTS; track request; let  i = $index) {
                <mat-chip class="neurun-ai-chip" color="primary" (click)="onAiRequestClick(i + 1)">{{ request }}</mat-chip>
              }
            </mat-chip-set>
          </div>
        </div>

        <div>
          <p class="chat-window-btn" (click)="openChatHistory()">View Chat History</p>
        </div>

        <div class="divider-wrapper">
          <mat-divider></mat-divider>
        </div>
      </div>

      <div *ngIf="start_point && end_point && path" class="block-container">
        <div style="display: flex; align-items: center; justify-content: space-between">
          <div style="display: flex; align-items: center">
            <img ngSrc="assets/images/map.svg" alt="Map" style="margin-right: 8px" height="24" width="24"/>
            <span class="section-title">Route Map</span>
          </div>
          <div style="display: flex; align-items: center; cursor: pointer" (click)="onMapViewSwitcherClick(isImmersiveViewOpen ? 'regular' : 'immersive')">
            <img src="assets/images/focus.svg" alt="Focus" style="margin-right: 8px" height="24" width="24" />
            <span class="action-button">{{ isImmersiveViewOpen ? '2D Map' : 'Immersive View' }}</span>
          </div>
        </div>
        <div>
          <span *ngIf="isImmersiveViewOpen; else defaultMapView">
            <app-map-immersive-view [path]="path" [startPoint]="start_point" [endPoint]="end_point" [raceUnit]="raceUnit" [waypoints]="waypoints"></app-map-immersive-view>
          </span>
          <ng-template #defaultMapView>
            <app-map-direction [startPoint]="start_point" [endPoint]="end_point" [path]="path" [raceUnit]="raceUnit" [waypoints]="waypoints"></app-map-direction>
          </ng-template>
        </div>
      </div>

      <div class="divider-wrapper">
        <mat-divider></mat-divider>
      </div>
      <div *ngIf="elevationsData && elevationsData?.length" class="block-container" style="margin-top: 32px">
        <div style="display: flex">
          <img ngSrc="assets/images/map.svg" alt="Map" style="margin-right: 8px" height="24" width="24"/>
          <span class="section-title">Elevation Profile</span>
        </div>
        <div>
          <app-elevation-profile [coordinates]="elevationsData" [units]="raceUnit" [showSelection]="false"></app-elevation-profile>
        </div>
      </div>
    </div>
  </div>
  <ng-template #desktopView>
    <div class="grid-container">
      <div class="grid-item item1" style="flex-direction: column">
        <!--        <div style="display: flex; flex-direction: column; align-items: center">-->
        <!--          <img [src]="race_image || 'assets/images/default-image.png'" alt="Race image" class="race-image" />-->
        <!--        </div>-->
        <div style="display: flex; width: 100%">
          <div class="race-info-container" style="width: 50%">
            <div>
              <p class="race-name">{{race.name}}</p>
              <div style="display: flex; gap: 4px; margin-top: 16px">
              <span class="race-info-box">
                <img ngSrc="assets/images/surface.svg" alt="Surface" height="12" width="12"/>
                <span class="race-info-title">{{ race.surface_type | titlecase }}</span>
              </span>
                <span class="race-info-box">
                <img ngSrc="assets/images/distance_run.svg" alt="Distance" height="12" width="12"/>
                <span class="race-info-title">{{ race.distance }} {{ raceUnit === 'metric' ? 'km' : 'mi' }}</span>
              </span>
              </div>
            </div>
            <div style="margin: 12px 0">
              <span style="font-weight: 500; font-size: 16px; color: #0B0B0B; line-height: 8px">
                {{race.starting_at | date:time_format}}, {{ race.starting_at | date:date_format }}
                <p style="margin-top: 8px; line-height: 19px;">
                  {{ race.location }}
                </p>
              </span>
              <div style="margin-top: 12px; font-weight: 500; font-size: 16px; color: #0B0B0B" *ngIf="temperature">
                <span *ngIf="userUnitSystem === raceUnit; else convertedTemperatureTemplate">
                  {{ temperature | number: '1.0-0' }}°{{raceUnit === 'metric' ? 'C' : 'F'}} <span style="color: #7E7E7E">- Avg. {{race.starting_at | date:time_format}} temp</span>
                </span>
                <ng-template #convertedTemperatureTemplate>
                  {{ temperature | temperatureConverter:userUnitSystem }} <span style="color: #7E7E7E">- Avg. {{race.starting_at | date:time_format}} temp</span>
                </ng-template>
              </div>
            </div>
          </div>
          <div style="display: flex; width: 50%; flex-direction: column; align-items: center; justify-content: center; gap: 16px">
            <div *ngIf="race.registration_url" style="margin-top: 24px; width: 100%">
              <app-custom-button title="Register" variant="primary" (click)="onLinkClick(race.registration_url)"></app-custom-button>
            </div>
            <div *ngIf="race.name.startsWith('California International Marathon')" class="ai-overview-container">
              <span class="action-button" style="cursor: default" >AI Podcast Course Overview</span>
              <div style="display: flex; align-items: center; gap: 20px">
                <img src="assets/images/spotify-logo.svg" alt="Spotify" (click)="onRaceOverviewClick('spotify')" class="ai-overview-icon" />
                <img src="assets/images/youtube-logo.svg" alt="YouTube" (click)="onRaceOverviewClick('youtube')" class="ai-overview-icon" />
                <img src="assets/images/apple-podcasts-logo.svg" alt="Apple Podcasts" (click)="onRaceOverviewClick('applePodcast')" class="ai-overview-icon" />
              </div>
            </div>
          </div>
        </div>

        <div class="divider-wrapper">
          <mat-divider></mat-divider>
        </div>
        <div *ngIf="start_point && end_point && path" class="block-container">
          <div style="display: flex; align-items: center; justify-content: space-between">
            <div style="display: flex; align-items: center">
              <img ngSrc="assets/images/map.svg" alt="Map" style="margin-right: 8px" height="24" width="24"/>
              <span class="section-title">Route Map</span>
            </div>
            <div style="display: flex; align-items: center; cursor: pointer" (click)="onMapViewSwitcherClick(isImmersiveViewOpen ? 'regular' : 'immersive')">
              <img src="assets/images/focus.svg" alt="Focus" style="margin-right: 8px" height="24" width="24" />
              <span class="action-button">{{ isImmersiveViewOpen ? '2D Map' : 'Immersive View' }}</span>
            </div>
          </div>
          <div>
            <span *ngIf="isImmersiveViewOpen; else defaultMapView">
              <app-map-immersive-view [path]="path" [startPoint]="start_point" [endPoint]="end_point" [raceUnit]="raceUnit" [waypoints]="waypoints"></app-map-immersive-view>
            </span>
            <ng-template #defaultMapView>
              <app-map-direction [startPoint]="start_point" [endPoint]="end_point" [path]="path" [raceUnit]="raceUnit" [waypoints]="waypoints"></app-map-direction>
            </ng-template>
          </div>
        </div>

        <div class="divider-wrapper">
          <mat-divider></mat-divider>
        </div>

        <div *ngIf="elevationsData && elevationsData?.length" class="block-container" style="margin-top: 32px; height: 330px">
          <div style="display: flex">
            <img ngSrc="assets/images/map.svg" alt="Map" style="margin-right: 8px" height="24" width="24"/>
            <span class="section-title">Elevation Profile</span>
          </div>
          <div>
            <app-elevation-profile [coordinates]="elevationsData" [units]="raceUnit" [showSelection]="false"></app-elevation-profile>
          </div>
        </div>

      </div>
      <div class="grid-item item2">
        <div style="margin: 16px 0">
          <div *ngIf="requestIndex && elevationsData?.length; else defaultPrompts">
            <div style="padding-bottom: 16px">
              <button class="back-btn" (click)="onChatBackClick()">
                <div style="display: flex; align-content: center; justify-content: center">
                  <img src="assets/images/arrow_back_purple.svg" alt="Arrow back" />
                  <span class="back-title">Back</span>
                </div>
              </button>
            </div>
            <app-race-ai-chat [request_index]="requestIndex" [isMobile]="false" [elevations_data]="elevationsData" [units]="userUnitSystem"></app-race-ai-chat>
          </div>
          <ng-template #defaultPrompts>
            <div style="display: flex">
              <img ngSrc="assets/images/chat_bubble.svg" alt="AI Chat" height="24" width="24" style="margin-right: 8px"/>
              <span class="section-title">Ask our AI Race Concierge:</span>
            </div>
            <div style="margin-top: 12px">
              <mat-chip-listbox style="max-width: 300px">
                @for (request of AI_REQUESTS; track request; let  i = $index) {
                  <mat-chip-option class="neurun-ai-chip" color="primary" (click)="onAiRequestClick(i + 1)">{{ request }}</mat-chip-option>
                }
              </mat-chip-listbox>
            </div>
            <div>
              <p class="chat-window-btn" style="justify-content: start; margin-left: 20px" (click)="openChatHistory()">View Chat History</p>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<ng-template #noRaceTemplate>
  <div class="no-races-message">No race available.</div>
</ng-template>
