<div>
  <apx-chart
    #elevation_chart
    [series]="series"
    [chart]="chart"
    [dataLabels]="dataLabels"
    [markers]="markers"
    [fill]="fill"
    [yaxis]="yaxis"
    [xaxis]="xaxis"
    [tooltip]="tooltip"
    [autoUpdateSeries]="true"
    [grid]="grid"
    [colors]="['#6271FF']"
  ></apx-chart>

  <div *ngIf="showSelection">
    <span class="selector-data-container">
      <form [formGroup]="elevationForm" style="width: 100%">
        <div class="form-container">
          <mat-form-field style="width: 40%">
            <input
              matInput
              id="start"
              formControlName="start"
              autocomplete="off"
              mask="separator.2"
              [suffix]="units === 'imperial' ? ' mi' : ' km'"
            >
            <mat-error *ngIf="start?.hasError('required')">
              Start value is required.
            </mat-error>
            <mat-error *ngIf="start?.hasError('min')">
              Start value cannot be negative.
            </mat-error>
          </mat-form-field>

          <span> - </span>

          <mat-form-field style="width: 40%">
            <input
              matInput
              id="finish"
              formControlName="finish"
              autocomplete="off"
              mask="separator.2"
              [suffix]="units === 'imperial' ? ' mi' : ' km'"
            >
            <mat-error *ngIf="finish?.hasError('required')">
              Finish value is required.
            </mat-error>
            <mat-error *ngIf="finish?.hasError('min')">
              Finish value cannot be negative.
            </mat-error>
          </mat-form-field>
        </div>
        <mat-error *ngIf="formError" style="margin-top: 8px; text-align: center">
          Please input a maximum of {{ (units === 'imperial' ? 'one mile' : '1.5 kilometer') }}.
        </mat-error>
      </form>
    </span>
  </div>
</div>
